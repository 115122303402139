import { Link } from 'gatsby';
import React from 'react';

export const PageNavigator = ({ currentPage, numPages, featured = false }) => {
  // const { currentPage, numPages, featured } = pageContext;
  numPages = numPages - 1;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const slug = featured ? '/obituaries/featured' : '/obituaries';
  const prevPage =
    currentPage - 1 === 1
      ? `${slug}/`
      : `${slug}/page/${(currentPage - 1).toString()}`;
  const nextPage = `${slug}/page/${(currentPage + 1).toString()}`;
  const allPages = Array.from({ length: numPages }, (_, i) =>
    currentPage === i + 1 ? (
      <span
        key={`pagination-number${i + 1}`}
        className='text-green-800 mx-2 text-lg'
      >
        {i + 1}
      </span>
    ) : (
      <Link
        key={`pagination-number${i + 1}`}
        to={`${slug}/${i === 0 ? '' : `page/${i + 1}`}`}
        className={`${
          currentPage === i + 1 ? `text-green-800` : `text-green-700`
        } hover:text-green-600 hover:underline mx-2 text-lg`}
      >
        {i + 1}
      </Link>
    )
  );

  const getDisplayedPageLinks = () => {
    if (numPages < 7) {
      return Array.from({ length: numPages }, (_, index) => allPages[index]);
    }
    const navPages = new Array(7);
    const ell = <span className='text-green-700 text-xs'>...</span>;
    navPages[0] = allPages[0];
    navPages[6] = allPages[allPages.length - 1];
    if (currentPage < 5) {
      navPages[1] = allPages[1];
      navPages[2] = allPages[2];
      navPages[3] = allPages[3];
      navPages[4] = allPages[4];
      navPages[5] = ell;
    }
    if (currentPage > allPages.length - 4) {
      navPages[1] = ell;
      navPages[2] = allPages[allPages.length - 5];
      navPages[3] = allPages[allPages.length - 4];
      navPages[4] = allPages[allPages.length - 3];
      navPages[5] = allPages[allPages.length - 2];
    }
    if (4 < currentPage && currentPage < allPages.length - 3) {
      navPages[1] = ell;
      navPages[2] = allPages[currentPage - 2];
      navPages[3] = allPages[currentPage - 1];
      navPages[4] = allPages[currentPage];
      navPages[5] = ell;
    }
    return navPages;
  };

  return (
    <>
      <div className='mt-6 grid grid-cols-3 md:grid-cols-3'>
        {isFirst ? (
          <div className='float-left' />
        ) : (
          <div className='col-start-1 col-span-1'>
            <span className='float-left'>
              <Link
                to={prevPage}
                rel='prev'
                className='text-sm md:text-base text-green-700 hover:text-green-600 hover:underline'
              >
                ← Previous
              </Link>
            </span>
          </div>
        )}
        <div className='col-start-2 col-span-1 text-center float-none justify-center'>
          <span>{getDisplayedPageLinks()}</span>
          <br />
          <span className='text-gray-500 text-xs text-center -mt-6'>Pages</span>
        </div>
        {!isLast && (
          <div className='col-start-3 md:col-start-3 col-span-1'>
            <span className='float-right'>
              <Link
                to={nextPage}
                rel='next'
                className='text-sm md:text-base text-green-700 hover:text-green-600 hover:underline'
              >
                Next →
              </Link>
            </span>
          </div>
        )}
      </div>
    </>
  );
};

import dayjs from 'dayjs';
import { Link, withPrefix } from 'gatsby';
import React from 'react';

import defaultImage from '../assets/images/sign-square.png';
import StyledButton from './styledButton';

const ObitPreview = ({
  slug,
  name,
  city,
  state,
  deathdate,
  age,
  image,
  excerpt,
}) => {
  const imageURL = image?.localFile?.publicURL
    ? withPrefix(image.localFile.publicURL)
    : defaultImage;

  return (
    <div className='w-full md:w-1/2 lg:w-1/3 mt-2'>
      <div className='h-full m-2 p-4 flex flex-col text-center pb-auto flex-auto'>
        <div className='w-full mb-4 flex-1'>
          <Link to={`/obituaries/${slug}`}>
            <div className=' my-auto py-auto mx-auto max-w-full'>
              {/* make this contain and set sizes for other viewports */}
              <img
                className='object-cover h-72 md:h-48 lg:h-64 xl:h-72 mx-auto max-w-full'
                alt={name}
                src={imageURL}
              />
            </div>
          </Link>
        </div>
        <div className=''>
          <Link
            to={`/obituaries/${slug}`}
            className='text-2xl w-full text-green-700 hover:text-green-600 hover:underline'
          >
            {name}
          </Link>
          <p>
            {city}, {state}
          </p>
          <p>
            Age {age} – Died {dayjs(deathdate).format('MM/DD/YYYY')}
          </p>
          <br />
          <p className='text-left mt-2' style={{ textIndent: '1rem' }}>
            {excerpt}
          </p>
          <Link to={`/obituaries/${slug}`}>
            <StyledButton className='mt-6'>Read More</StyledButton>
          </Link>
        </div>
        <div className='h-full' />
      </div>
      <div className='self-center mx-auto items-center'></div>
    </div>
  );
};

export default ObitPreview;

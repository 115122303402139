// import Guarantee from '../components/guarantee';
// import Layout from '../components/layout';
// import ObitPreview from '../components/obitPreview';
// import SEO from '../components/seo';
import banner from "../assets/images/leafBannerSmaller.jpg";
import Guarantee from "../components/guarantee";
import Layout from "../components/layout";
import ObitPreview from "../components/obitPreview";
import { PageNavigator } from "../components/pageNavigator";
import SearchBar from "../components/search-bar";
import SEO from "../components/seo";
import Services from "../components/services";
import { navigate } from "@gatsbyjs/reach-router";
import { Link, graphql } from "gatsby";
import React from "react";

const ObitList = ({ data, pageContext }) => {
  const obituaries = data.allStrapiArticle.edges;
  const { numObits, featured } = pageContext;
  const handleSearchSubmit = (v: string): void => {
    navigate(`/obituaries/search?q=${v}`);
  };

  return (
    <Layout
      headerClass="relative bg-white"
      hasHero={false}
      heroSize="20rem"
      heroImage={banner}
      className={undefined}
      bodyClass={undefined}
      heroHead={undefined}
      heroSubHead={undefined}
      heroText={undefined}
    >
      <SEO
        title="Obituaries | City of Oaks Cremation & Funeral Home"
        description="We help families honor their loved ones every day. City of Oaks Cremation is a full-service funeral home in Raleigh serving all of NC and beyond."
        image={undefined}
        url={undefined}
        pathname={undefined}
      />
      <h1 className="mb-2 text-4xl text-gray-800 self-center text-center mt-10">
        Obituaries
      </h1>
      <div className="flex flex-col mt-4">
        <div>
          <SearchBar handleSubmit={handleSearchSubmit} numObits={numObits} />
        </div>

        {/* <h1 className='text-xl mt-2 mb-6'>
          Here are celebrations of the lives of some of the individuals who have
          been entrusted to City of Oaks Funeral Home and Cremations.
        </h1> */}
        <p className="text-xl mt-2 mb-6">
          We’re honored to be able to provide every family that chooses City of
          Oaks Funeral Home and Cremations a free-of-charge, shareable online
          obituary to honor their loved ones as well as an easy-to-use platform
          that allows them to update it in their own time.
          {featured ? (
            <>
              {" "}
              Here are recent obituaries that families have posted. If you’re
              looking for a specific obituary, you can search above, or view all
              obituaries{" "}
              <Link
                className="text-green-700 hover:text-green-600 underline"
                to="/obituaries/"
              >
                here.
              </Link>
            </>
          ) : (
            <>
              {" "}
              You&apos;re viewing all obituaries, including ones that
              haven&apos;t been updated yet. Search for a specific obituary
              above, or view only updated obituaries{" "}
              <Link
                className="text-green-700 hover:text-green-600 underline"
                to="/obituaries/featured/"
              >
                here.
              </Link>
            </>
          )}
        </p>

        <div className="flex flex-wrap justify-center items-stretch -mx-2">
          {/* <div className="flex flex-wrap"> */}
          {obituaries.map(({ node }) => {
            return (
              <ObitPreview
                {...node}
                excerpt={
                  node.content.data.content
                    ? `${node.content.data.content.slice(0, 280)}${
                        node.content.data.content.length > 280 ? "..." : ""
                      }`
                    : ""
                }
              />
            );
          })}
        </div>
        <PageNavigator {...pageContext} />
      </div>
      <Guarantee className={undefined} />
      <Services />
    </Layout>
  );
};

export const query = graphql`
  query ObituariesListQuery($skip: Int!, $limit: Int!, $featured: Boolean) {
    allStrapiArticle(
      filter: { user_updated: { in: [true, $featured] } }
      sort: { fields: deathdate, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          name
          content {
            data {
              content
            }
          }
          city
          county
          state
          deathdate(formatString: "MM/DD/YYYY")
          age
          user_updated
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;

export default ObitList;
